/* eslint-disable max-len */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import HeroImage from '../../../components/page/HeroImage';
import Title from '../../../components/page/Title';
import ShortDescription from '../../../components/page/ShortDescription';
import Content from '../../../components/page/Content';
import RelatedProducts from '../../../components/page/RelatedProducts';
import RelatedArticlePages from '../../../components/page/RelatedArticlePages';
import RelatedRecipePages from '../../../components/page/RelatedRecipePages';
import CloudinaryImage from '../../../components/shared/CloudinaryImage';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
  },
  image: { borderRadius: '0' },
});

function BrandPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulBrand');
  const analytics = get(pageData, 'mainTheme.analytics');

  const heroImage = get(pageData, 'heroImage');
  const brandLogoImage = get(pageData, 'brandLogoImage');
  const imageTitleText = get(brandLogoImage, 'imageTitleText');
  const altText = get(brandLogoImage, 'imageAltText');
  const publicId = get(brandLogoImage, 'cloudinaryImage.0.public_id');

  const variants = useMemo(
    () => [
      {
        transformation: 'FullWidthImage-XS-IN',
        width: 430,
      },

      {
        transformation: 'FullWidthImage-SM-IN',
        width: 768,
      },

      {
        transformation: 'FullWidthImage-MD-IN',
        width: 1024,
      },

      {
        transformation: 'FullWidthImage-LG-IN',
        width: 1440,
      },
    ],
    [],
  );

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <Title pageData={pageData} />
        <ShortDescription pageData={pageData} />
        <HeroImage pageData={pageData} data={heroImage} height={570}>
          <CloudinaryImage
            className={classes.image}
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={imageTitleText}
            width="auto"
            height="auto"
          />
        </HeroImage>
        <Content pageData={pageData} />
        <RelatedProducts pageData={pageData} />
        <RelatedArticlePages pageData={pageData} />
        <RelatedRecipePages pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

BrandPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

BrandPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query BrandPageTemplate($pageId: String!) {
    contentfulBrand(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      languageCode
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      brandLogoImage {
        ...ContentImageModuleFragment
      }
      content {
        ...ContentFragment
      }
      relatedProducts {
        ...RelatedProductFragment
      }
      relatedArticlePages {
        ...RelatedArticlePageFragment
      }
      relatedRecipePages {
        ...RelatedRecipePageFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(BrandPageTemplate));
